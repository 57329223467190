import { ParagraphActivitySlider } from '../../../generated/types';
import Container from '../../Layout/Container';
import ActivityTeaser from '../../Node/Activity/ActivityTeaser';
import Wrapper from '../Wrapper';
import React from 'react';
import ParagraphTitle from '../ParagraphTitle';
import TeaserSlider from '../../Common/TeaserSlider';

interface ActivitySliderProps {
  data: ParagraphActivitySlider;
}

const ActivitySlider: React.FC<ActivitySliderProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Wrapper className="PargraphActivitySlider">
      <Container>
        <TeaserSlider
          TitleComponent={
            <ParagraphTitle label={data.title} sub={data.subtitle} />
          }
          data={data.data!.items || []}
          CardComponent={ActivityTeaser}
        />
      </Container>
    </Wrapper>
  );
};

export default ActivitySlider;
